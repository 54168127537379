export const generatedRoutes = [
   { 
       path: "design-spa", 
       component: async () => await import("@/spa/pages/Demo1.vue"),
       name: "Demo1",
       meta: {
           title: "Demo1",
       },
   },
   { 
       path: "design-spa/demo", 
       component: async () => (await import("@/spa/pages/Demo2")).Demo2,
       name: "Demo2",
       meta: {
           title: "Demo2",
       },
   },
   { 
       path: "portal-admin/:corporateId/curatingContent/sensitive-tags", 
       component: async () => (await import("@/spa/components/b2b/SensitiveTags")).SensitiveTags,
       name: "Sensitive Tags",
       meta: {
           title: "Sensitive Tags",
       },
   },
   { 
       path: "portal-admin/:corporateId/impact/reports", 
       component: async () => (await import("@/components/stats/B2BStatsReportPage")).B2BStatsReportPage,
       name: "Impact (Reports)",
       meta: {
           title: "Impact (Reports)",
       },
   },
   { 
       path: "portal-admin/:corporateId/impact/statistics", 
       component: async () => (await import("@/spa/components/b2b/StatisticsDashboard")).StatisticsDashboard,
       name: "Impact (Statistics)",
       meta: {
           title: "Impact (Statistics)",
       },
   },
];
